/* eslint-disable max-len */

import React from 'react';
import styled from 'styled-components';

import SEO from 'components/SEO';

import gomastitImage from './images/gomastit.gif';

const StyledImage = styled.img`
  float: left;
  margin: 0 1em 0.1em 0;

  @media (max-width: 500px) {
    width: 140px;
  }
`;

const MaterialPage = () => (
  <>
    <SEO title="Material" keywords={[]} />
    <h1>Gomastit von Merz+Benteli</h1>
    <div style={{ float: 'left', margin: '0 1em 0.1em 0' }}>
      <StyledImage src={gomastitImage} alt="" />
      <p className="caption" />
    </div>
    <p>Die fundierten Klebstoffkenntnisse führten frühzeitig zur Entwicklung von elastischen Dichtstoffen für die Elementbauweise. 1958 wurden erstmals in der Schweiz bei Merz+Benteli für die neue Bautechnologie elastische 2K-Polysulfid-Dichtstoffe produziert. 1K-Polysulfid- und Silikondichtstoffe folgten 1969. Die breite Dichtstoffpalette, die unermülich verbessert und erweitert wurde, hat sich lägst beim Verarbeiter unentbehrlich gemacht. Das solide Know-how hat den Weg zur technologisch fürenden Stellung in Europa geebnet und die Lancierung der erfolgreichen 1K-MS-Hybrid-Polymer-Dichtstoffe ermölicht.</p>
    <p style={{ textAlign: 'right' }}>
      <cite>
Text ©
        <a href="http://www.merz-benteli.ch/" target="_blank" rel="noopener noreferrer">Merz+Benteli AG</a>
      </cite>
    </p>
  </>
);

export default MaterialPage;
